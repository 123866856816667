import { policyList } from "@/enums/permissions";
import { Batch } from "@/interfaces/batch";
import { Brand, BrandLogo, BrandModel } from "@/interfaces/brand";
import { PusherNotification } from "@/interfaces/notification";
import { WeedmapsData } from "@/interfaces/WeedmapsData";
import { pusherEvents } from "@/internal";
import { weedMapsEnabled } from "@/router.utils";
import { brandService } from "@/services/brand.service";
import { weedmapsInfoService } from "@/services/weedmapsInfo.service";
import { Callback, PageNavAction } from "@/types/types";
import { BooleanCheck } from "helix-vue-components";
import cloneDeep from "lodash/cloneDeep";
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import BasicInfoComponent from "./basicInfo/BasicInfo.component";
import { BatchesAssignedComponent } from "./batchesAssigned/BatchesAssigned.component";
import Template from "./BrandEdit.template.vue";
import { ProductsAssignedComponent } from "./productsAssigned/ProductsAssigned.component";
import WeedmapsInfoComponent from "./weedmapsInfo/WeedmapsInfo.component";

const namespace: string = "BrandModule";

enum TabsEnum {
  batches_assigned = "batches-assigned",
  products_assigned = "products-assigned",
  basic_info = "basic-info",
  weedmaps_info = "weedmaps-info"
}
@Component({
  mixins: [Template],
  components: {
    "basic-info": BasicInfoComponent,
    "products-assigned": ProductsAssignedComponent,
    "batches-assigned": BatchesAssignedComponent,
    "weedmaps-info": WeedmapsInfoComponent
  },
  inject: ["$changes"]
})
export default class BrandsEditComponent extends Vue {
  @Getter("brand", { namespace }) public brandData!: Brand;
  public isNewBrand: boolean = false;

  @Getter("batches", { namespace }) public batches!: Batch[];

  @Getter("hasPermission", { namespace: "PermissionsModule" })
  public hasPermission!: BooleanCheck;

  @Action("loadBrand", { namespace })
  public getBrandAction!: Callback;

  @Action("setPageNav", { namespace: "PageNavModule" })
  public setPageNav!: PageNavAction;

  @Action("destroy", { namespace })
  public destroyProductAction!: Callback;

  public brandTabs = 0;

  public tabsEnum = TabsEnum;

  public hasModifyPermission: boolean = false;

  public weedmapsData: WeedmapsData | null = null;

  public isLoading = false;

  public hasWmIntegration = weedMapsEnabled();

  public model: BrandModel = {
    brand_vendors: [],
    name: "",
    location_id: null
  };

  public async save() {
    let response;
    this.isLoading = true;
    if (this.isNewBrand) {
      if (this.model.brand_vendors! && !this.model.brand_vendors!.length) {
        delete this.model!.brand_vendors;
      }
      response = await brandService.createBrand(this.model);
    } else {
      if (
        !this.model.brand_vendors!.length &&
        !this.brandData.brand_vendors!.length
      ) {
        delete this.model.brand_vendors;
      }
      this.brandData.brand_vendors!.map(brand => {
        const index = this.model.brand_vendors!.findIndex(
          (brandIndex: any) => brandIndex.vendor_id === brand.vendor_id
        );
        if (index === -1) {
          this.model.brand_vendors!.push({
            vendor_id: String(brand.vendor_id),
            _destroy: true
          });
        }
      });
      response = await brandService.uptadeBrand(this.model, this.brandData);
    }
    if (this.hasWmIntegration && this.weedmapsData && response) {
      const model: WeedmapsData = {
        biotrack_brand_id: String(response!.id!),
        weedmaps_brand_id:
          (this.weedmapsData && this.weedmapsData.weedmaps_brand_id) || "",
        name: response!.name
      };
      await weedmapsInfoService.createBrandsWeedmaps(model);
    }
    this.isLoading = false;
    this.back();
  }

  public back() {
    this.$router.back();
  }

  public async loadBrand() {
    this.isLoading = true;
    await this.getBrandAction(this.$route.params.id);
    // @ts-ignore
    this.model = {
      name: this.brandData.name,
      brand_vendors: (this.brandData.vendors || []).map(vendor => ({
        vendor_id: vendor.id
      })),
      location_id: this.brandData.location_id
    };
    this.isLoading = false;
  }
  public onChangeBrands(data: WeedmapsData) {
    this.weedmapsData = data;
  }

  public async created() {
    if (this.$route.params.id) {
      this.loadBrand();
    } else {
      this.isNewBrand = true;
    }
  }

  get productsAssignedVisibility(): boolean {
    return !!this.brandData.id && this.brandTabs === 1;
  }

  get batchesAssignedVisibility(): boolean {
    return !!this.brandData.id && this.brandTabs === 2;
  }

  get currentImg() {
    const brandLogo = this.brandData.logo as BrandLogo;

    return this.brandData && this.brandData.logo && brandLogo.logo_file_name
      ? brandLogo.logo_original_url
        ? String(brandLogo.logo_original_url)
        : this.brandData.logo
      : "";
  }

  public destroyed() {
    this.destroyProductAction();
  }

  protected mounted() {
    if (this.$route.name === "brand-view") {
      this.hasModifyPermission = true;
    }
    this.setPageNav({
      title: "brand.brands",
      isLoading: () => this.isLoading,
      rightActions: {
        generalActions: () => [
          {
            icon: "fal fa-check",
            action: this.save,
            vuetifyProps: () => ({
              disabled: !this.model.name,
              loading: this.isLoading,
              fab: true,
              small: true
            })
          },
          {
            icon: "fal fa-times",
            action: this.back,
            loading: this.isLoading,
            fab: true,
            small: true
          }
        ]
      }
    });
    this.$changes.watch(
      pusherEvents.brandTouched,
      this.loadBrand,
      (data: PusherNotification) => {
        return (
          !this.isNewBrand &&
          !!data.message.find(n => n.item_id === this.brandData.id)
        );
      }
    );
  }
}
