import ItemAggregatorComponent from "@/components/retail/discountManager/discountManagerAdd/build-blocks/ItemAggregator/ItemAggregator.component";
import SelectImageComponent from "@/components/sharedComponents/selectImage/SelectImage.component";
import { BrandAddVendor, BrandModel } from "@/interfaces/brand";
import { Location } from "@/interfaces/location";
import { SharingData } from "@/interfaces/sharing";
import { User } from "@/interfaces/user";
import { Vendor } from "@/interfaces/vendor";
import { permissionService } from "@/services/permission.service";
import { sharingService } from "@/services/sharing.service";
import { vendorService } from "@/services/vendor.service";
import { Callback } from "@/types/types";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import Template from "./BasicInfo.template.vue";

const namespace = "AuthModule";

@Component({
  mixins: [Template],
  components: {
    "select-image": SelectImageComponent,
    "item-aggregator": ItemAggregatorComponent
  }
})
export default class BasicInfoComponent extends Vue {
  public getVendorsAction!: Callback;
  public vendors: Array<{ name: string; value: string | number }> = [];
  public selected: string[] = [];
  @Getter("user", { namespace })
  public user!: User;
  @Prop() public model!: BrandModel;
  @Prop({ default: "" }) public currentImage!: string;
  public location: Location[] = [];
  public sharingData: SharingData | null = null;
  public hasSeperateBrand: boolean = false;
  @Prop({ default: false }) public modifyPermission!: boolean;

  public async mounted() {
    this.shareData();
    this.location = await permissionService.getUserLocations(this.user.id);
    this.getVendors();
  }

  public async shareData() {
    this.sharingData = await sharingService.getSharing();
    if (this.sharingData!.entities.BRAND.separated.length) {
      this.hasSeperateBrand = true;
    } else {
      this.hasSeperateBrand = false;
    }
  }

  public fileSelected(file: File) {
    this.model.logo = file;
  }

  public async getVendors() {
    const response = await vendorService.get({
      no_pagination: true
    });
    this.vendors = response.data.data.map((vendor: Vendor) => ({
      value: vendor.id,
      name: vendor.name
    }));
  }

  public get selectedVendors() {
    return this.model.brand_vendors
      ? this.model.brand_vendors.map(
          (vendor: any) =>
            !vendor._destroy && String((vendor as BrandAddVendor).vendor_id)
        )
      : [];
  }

  public select(vendors: string[]) {
    this.model.brand_vendors = vendors.map(vendorId => ({
      vendor_id: vendorId
    }));
  }
}
